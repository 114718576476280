<style scoped lang="scss">
.no-items {
  position: relative;
  top: 50%;
  text-align: center;
}

$layer-container-padding: 10px;
.layers-container {
  position: relative;
  padding: 10px $layer-container-padding;
  width: calc( 100% - ($layer-container-padding * 2) );
  font-size: 18px;
  height: calc( 100% - 80px );

  .layers {
    position: relative;
    height: calc( 100% - 64px );
    overflow: auto;
    padding: 10px;
    background-color: rgba(0,0,0,0.5);
  }

  .layer {
    background-color: white;
    padding: 10px 10px;
    border-radius: 4px;
    margin-top: 10px;
    border-radius: 4px;

    &.header {
      padding: 20px 20px 10px 20px;
      background-color: rgba(0, 0, 0, 0.5);

      > .color {
        background-color: transparent;
      }
    }

    &:first-child {
      margin-top: 0px;
    }

    > * {
      display: inline-block;
      font-size: 0px;
      vertical-align: middle;
    }

    .color {
      margin-right: 10px;
      width: 4px;
      height: 21px;
      border-radius: 4px;
    }

    .name {
      position: relative;
      margin-left: 10px;
      width: calc( 100% - 72px );
    }

    .value {
      position: relative;
      width: 100%;
      display: inline-block;
      font-size: 18px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }

    .icon {
      width: 24px;
      height: 24px;
      background-size: 18px;
      background-repeat: no-repeat;
      background-position: center;
      cursor: pointer;
      transition: all 250ms;

      &:hover {
        background-size: 22px;
      }

      &.bulb {
        background-image: url("@/assets/images/light-bulb-off.png");
        &.on {
          background-image: url("@/assets/images/light-bulb-on.png");
        }
        &.mid {
          background-image: url("@/assets/images/light-bulb-on.png");
          opacity: 0.5;
        }
      }

      &.freeze {
        background-image: url("@/assets/images/freeze-off.png");
        &.on {
          background-image: url("@/assets/images/freeze-on.png");
        }
        &.mid {
          background-image: url("@/assets/images/freeze-on.png");
          opacity: 0.5;
        }
      }
    }
  }
}

.tooltip-arrow {
  z-index: 9999;
}
</style>
<script setup>
import {computed, watch, ref} from "vue";
import TheSide from "@/components/side/TheSide.vue";
import titleImage from "@/assets/images/layers.png";
import SearchFilter from "@/components/SearchFilter.vue";
const props = defineProps({
  items: {
    type: Array,
    default() {
      return [];
    }
  },
  backgroundColor: {
    type: Object,
    default() {
      return { r: 255, g: 255, b: 255 };
    }
  },
  fontColor: {
    type: Object,
    default() {
      return { r: 0, g: 0, b: 0 };
    }
  },
  tooltipTheme: {
    type: String,
    default: 'white'
  }
});

const emits = defineEmits(['clickBackButton', 'clickBulbIcon', 'clickFreezeIcon', 'clickHeaderBulbIcon', 'clickHeaderFreezeIcon']);
const tooltipVisibles = ref([]);
const searchKeyword = ref('');
const searchFilter = ref();

const headerFreezeClass = computed(() => {

  const itemFrozenCounts = props.items.reduce((frozenCounts, item) => {
    if( item.frozen ){
      return frozenCounts + 1;
    }

    return frozenCounts;
  }, 0);

  if( itemFrozenCounts === props.items.length ){
    return 'on';
  }

  if( 0 < itemFrozenCounts ){
    return 'mid';
  }

  return 'off'

});

const headerBulbClass = computed(() => {

  const itemVisibleCounts = props.items.reduce((visibleCounts, item) => {
    if( item.visible ){
      return visibleCounts + 1;
    }

    return visibleCounts;
  }, 0);

  if( itemVisibleCounts === props.items.length ){
    return 'on';
  }

  if( 0 < itemVisibleCounts ){
    return 'mid';
  }

  return 'off'

});

const searchedItems = computed(() => {

  const keyword = searchKeyword.value.toLowerCase();
  return props.items.filter((item) => {

    return -1 < item.name.toLowerCase().indexOf( keyword );

  });

});

watch(() => props.items, () => {

  methods.clearKeyword();

});

const methods = {
  numberToHexString( number ) {
    return `#${number.toString(16).padStart(6, '0')}`;
  },

  onClickBackButton: () => {
    emits('clickBackButton');
  },

  hideTooltipAll: () => {
    tooltipVisibles.value.forEach(( visible, index ) => {
      tooltipVisibles.value[ index ] = false;
    });
  },

  getLayerIndexByEvent: ( event ) => {
    const layer = event.target.closest('.layer') || event.target;
    return Array.prototype.indexOf.call(event.currentTarget.children, layer);
  },

  showLayerTooltipButOthers: ( event ) => {

    methods.hideTooltipAll();
    const index = methods.getLayerIndexByEvent( event );

    if( index < 0 ){
      return;
    }

    tooltipVisibles.value[index] = true;

  },

  onClickLayers: (event) => {

    methods.showLayerTooltipButOthers( event );

  },

  onMouseoverLayer: (event) => {

    methods.showLayerTooltipButOthers( event );

  },

  focusLayer: (event) => {

    methods.showLayerTooltipButOthers( event );

  },

  clearKeyword: () => {

    searchKeyword.value = '';

  },

  onClickBulbIcon: ( layer ) => {

    emits('clickBulbIcon', layer);

  },

  onClickFreezeIcon: ( layer ) => {

    emits('clickFreezeIcon', layer);

  },

  onClickHeaderBulbIcon: () => {

    emits('clickHeaderBulbIcon');

  },

  onClickHeaderFreezeIcon: () => {

    emits('clickHeaderFreezeIcon');

  }

}

defineExpose({

  hideTooltipAll: () => {
    methods.hideTooltipAll();
  }

});
</script>
<template>
  <the-side
      :title-image="titleImage"
      :title-text="'레이어 목록'"
      :background-color="props.backgroundColor"
      :font-color="props.fontColor"
      @clickBackButton="methods.onClickBackButton"
  >
    <search-filter ref="searchFilter" v-model="searchKeyword"
                   style="padding: 10px;"
                   :backgroundColor="{ r: props.fontColor.r, g: props.fontColor.g, b: props.fontColor.b, a: 0.2 }"
                   :fontColor="{ r: props.fontColor.r, g: props.fontColor.g, b: props.fontColor.b, a: 1 }"
    ></search-filter>
    <div class="layers-container">
          <div class="layer header">
            <div class="color"></div>
            <div class="icon bulb" :class="{[headerBulbClass]: true}" @click="methods.onClickHeaderBulbIcon"></div>
            <div class="icon freeze" :class="{[headerFreezeClass]: true}" @click="methods.onClickHeaderFreezeIcon"></div>
            <div class="name" :style="{'color': props.backgroundColor }">
              <span class="value">레이어명</span>
            </div>
          </div>
          <div class="layers" @click="methods.onClickLayers($event)" @mouseover="methods.onMouseoverLayer($event)" @focus="methods.focusLayer($event)">
            <template v-if="searchedItems.length">

              <div :key="item.name" v-for="(item, index) in searchedItems" class="layer"
                   :style="{'background-color': `rgba(${props.fontColor.r},${props.fontColor.g},${props.fontColor.b}, 0.2)`}"
                   v-tooltip.top="{theme: props.tooltipTheme, content: item.name, triggers: [], shown: tooltipVisibles[index],  hideTriggers: []}">
                <div class="color" :style="{ 'background-color': methods.numberToHexString( item.color ) }"></div>
                <div class="icon bulb" :class="{on: item.visible}" @click="methods.onClickBulbIcon(item)"></div>
                <div class="icon freeze" :class="{on: item.frozen}" @click="methods.onClickFreezeIcon(item)"></div>
                <div class="name" :style="{'color': `rgb(${props.fontColor.r},${props.fontColor.g},${props.fontColor.b})`}">
                  <span class="value">{{item.name}}</span>
                </div>
              </div>
            </template>
            <template v-else>
                <div class="no-items">레이어가 없습니다.</div>
            </template>
          </div>
    </div>
  </the-side>
</template>