import { createApp } from 'vue'
import FloatingVue from 'floating-vue'
import App from './App.vue'
import 'floating-vue/dist/style.css';
import '@/assets/styles/transition.css';
import '@/assets/styles/floating-vue-theme.css';

const app = createApp(App);
app.use(FloatingVue, {
    defaultHideOnTargetClick: false,
    defautTrigger: 'hover focus click',
    themes: {
        'white': {
            $extend: 'tooltip',
            distance: 10
        },
        'black': {
            $extend: 'tooltip',
            distance: 10
        }
    }
});
app.mount('#app');