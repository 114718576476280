const Util = {
    numberWithCommas(x = 0) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },

    toMb(bytes) {
        return this.numberWithCommas(( bytes / 1024 / 1024 ).toFixed(2));
    },

    traverseElement( callback ) {

        callback( this );
        Array.prototype.forEach.call(this.children, ( childElement ) => {
            Util.traverseElement.call( childElement, callback );
        });

    },

    isMobile() {
        const mobileRegex = [
            /Android/i,
            /iPhone/i,
            /iPad/i,
            /iPod/i,
            /BlackBerry/i,
            /Windows Phone/i
        ]

        return mobileRegex.some(mobile => window.navigator.userAgent.match(mobile));
    }
};

export default Util;