<style>
.viewer {
  position: relative;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.viewer.disabled {
  pointer-events: none;
}
</style>
<script setup type="module">
import {onMounted, onBeforeUnmount, ref, watch} from 'vue';
import { SGKDxfViewer, EVENT, MOUSE_ACTION, MOUSE_BUTTON } from '@sgk-npm/sgk-dxf-viewer';
import Util from '@/modules/Util.js';
import font1 from '@/assets/fonts/Roboto-LightItalic.ttf';
import font2 from '@/assets/fonts/NotoSansDisplay-SemiCondensedLightItalic.ttf';
import font3 from '@/assets/fonts/HanaMinA.ttf';
import font4 from '@/assets/fonts/NanumGothic-Regular.ttf';

const viewer = ref();
const dummyA = document.createElement('a');
const workerFactory = () => new Worker("/javascripts/SGKDxfLoader.worker.prod.js");
// todo: 같은 소스지만, 동적으로 불러올 경우 폰트를 불러올 수 없어진다.
// const workerFactoryPromise = fetch("https://product.smartgeokit.com/sgk-dxf-viewer/v1.0.2/dist/SGKDxfLoader.worker.prod.js").then((response) => {
//   return response.blob();
// }).then((blob) => {
//   return () => new Worker(URL.createObjectURL(blob));
// });

let dxfViewer = null;

const props = defineProps({
  dxfUrl: {
    type: String,
    required: false
  },
  disabled: {
    type: Boolean,
    default: true
  }
});
const emits = defineEmits([ 'loadDxf', 'viewChanged', 'progress', 'loadError' ]);
const methods = {
  loadDxf: async () => {
    try{

      const dxfInformation = await dxfViewer.load(props.dxfUrl, workerFactory);
      emits('loadDxf', dxfInformation);
    }catch(error){
      emits('loadError', error);
    }
  }
};

watch(() => props.dxfUrl, () => {
  if( !props.dxfUrl ){
    return;
  }
  methods.loadDxf();
});

onMounted(() => {

  dxfViewer = new SGKDxfViewer(viewer.value, [
      font1,
      font2,
      font3,
      font4
  ]);

  dxfViewer.addEventListener(EVENT.PROGRESS, (stepName, now, max) => {
    emits('progress', stepName, now, max);
  });

  dxfViewer.addEventListener(EVENT.VIEW_CHANGED, () => {
    emits('viewChanged');
  });

  dxfViewer.addEventListener(EVENT.LOAD_ERROR, (error) => {
    emits('loadError', error);
  });

  if( Util.isMobile() === false ){
    dxfViewer.useDefaultCursor();
    dxfViewer.setMouseActionToButton( MOUSE_BUTTON.LEFT, MOUSE_ACTION.DRAG_SELECT_BOX );
  }

});

onBeforeUnmount(() => {

  dxfViewer.destroy();
  dxfViewer = null;

});

defineExpose(Object.entries({
  download: () => {

    const dxfInformation = dxfViewer.getDxfInformation();
    dummyA.download = `${dxfInformation.name.split('.')[0]}.png`;
    dummyA.href = dxfViewer.toDataURL();
    dummyA.click();

  },

  getLayers: () => {

    return dxfViewer.getLayers();

  },

  setVisibleLayer: ( name, visible ) => {

    dxfViewer.setVisibleLayer( name, visible );

  },

  setLineColor: (...rgbArray) => {

    // 인자값 갯수를 적용하기 위한 코드
    dxfViewer.setLineColor(...rgbArray);

  },

  setBackgroundColor: (...rgbArray) => {

    // 인자값 갯수를 적용하기 위한 코드
    dxfViewer.setBackgroundColor(...rgbArray);

  },

  zoomIn: () => {

    dxfViewer.zoomIn();

  },

  zoomOut: () => {

    dxfViewer.zoomOut();

  },

  zoomFit: () => {

    dxfViewer.zoomFit();

  },

  getZoom: () => {

    return dxfViewer.getZoom();

  }

}).reduce(( expose, [key, fn] ) => {

  expose[ key ] = (...args) => {

    if( dxfViewer === null ){
      return;
    }

    return fn(...args);

  };

  return expose;

}, {}));
</script>

<template>
  <div ref="viewer" class="viewer" :class="{disabled: props.disabled}"></div>
</template>