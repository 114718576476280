import commonApi from '@/api/common';
export default {
    download: async ( id ) => {

        const method = 'GET';
        const url = `${process.env.VUE_APP_API_END_POINT}/file/download/${id}`;

        const response = await commonApi.request(url, method);
        return response.blob();

    }
}