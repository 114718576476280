<style scoped>
.grid {
  display: grid;
  padding: 20px 40px;
  width: calc( 100% - 80px );
  grid-template-columns: 1fr 3fr;
  font-size: 18px;
  column-gap: 20px;
  grid-auto-rows: minmax(40px, auto);
  justify-items: left;
  align-items: center;
}

.col-1 {
  font-weight: bold;
}

.col-2 {
  text-align: left;
}

.image-container {
  margin-top: 20px;
  margin-left: 20px;
  width: calc( 100% - 40px );
  height: 200px;
  border-radius: 4px;
  background-color: black;
}

.image-container > img {
  object-fit: scale-down;
  width: 100%;
  height: 100%;
}
</style>
<script setup>
import Util from "@/modules/Util.js";
import TheSide from "@/components/side/TheSide.vue";
import titleImage from "@/assets/images/information.png";
const emits = defineEmits(['clickBackButton']);
const props = defineProps({
  dxfInformation: {
    Type: Object,
    default() {
      return {
        name: '',
        thumbnail: '',
        bytes: 0,
        entities: 0,
        blocks: 0,
        layers: 0,
        origin: { x: 0, y: 0 },
        boundary: {
          min: { x: 0, y: 0 },
          max: { x: 0, y: 0 }
        }
      };
    }
  },
  backgroundColor: {
    type: Object,
    default() {
      return { r: 255, g: 255, b: 255 };
    }
  },
  fontColor: {
    type: Object,
    default() {
      return { r: 0, g: 0, b: 0 };
    }
  }
});

const methods = {
  onClickBackButton() {
    emits('clickBackButton');
  }
}

</script>
<template>
  <the-side
      :title-image="titleImage"
      :title-text="'도면 정보'"
      :background-color="props.backgroundColor"
      :font-color="props.fontColor"
      @clickBackButton="methods.onClickBackButton"
  >
    <div class="image-container">
      <img :src="props.dxfInformation?.thumbnail" height="200">
    </div>
    <div class="grid">
      <div class="col-1">도면명</div>
      <div class="col-2">{{ props.dxfInformation?.name }}</div>
      <div class="col-1">도면용량</div>
      <div class="col-2">{{ Util.toMb( props.dxfInformation?.bytes ) }}MB</div>
      <div class="col-1">레이어수</div>
      <div class="col-2">{{ Util.numberWithCommas( props.dxfInformation?.layers ) }}개</div>
      <div class="col-1">블럭수</div>
      <div class="col-2">{{ Util.numberWithCommas( props.dxfInformation?.blocks ) }}개</div>
      <div class="col-1">엔티티수</div>
      <div class="col-2">{{ Util.numberWithCommas( props.dxfInformation?.entities ) }}개</div>
      <div class="col-1">원점</div>
      <div class="col-2">{{ props.dxfInformation?.origin?.x?.toFixed(2) }}, {{ props.dxfInformation?.origin?.y?.toFixed(2) }}</div>
      <div class="col-1">바운더리</div>
      <div class="col-2">{{ props.dxfInformation?.boundary?.min?.x?.toFixed(2) }}, {{ props.dxfInformation?.boundary?.min?.y?.toFixed(2) }}<br>
        {{ props.dxfInformation?.boundary?.max?.x?.toFixed(2) }}, {{ props.dxfInformation?.boundary?.max?.y?.toFixed(2) }}
      </div>
    </div>
  </the-side>
</template>