<style scoped>
.spinner-container {
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  z-index: 1000;
  background-color: rgba( 0, 0, 0, 0.5 );
}

.contents {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.spinner {
  position: relative;
  width: 50%;
  height: 50%;
  max-width: 400px;
  max-height: 400px;
}

.message {
  font-family: sans-serif;
  font-size: 18px;
  text-align: center;
  color: #fff;
  height: 18px;
  text-shadow: -1px 0 #000, 0 1px #000, 1px 0 #000, 0 -1px #000;
}
</style>

<script setup type="module">
import {onMounted, ref} from 'vue';
import Lottie from 'lottie-web';
import animationData from '@/assets/images/lotties/spinner.json';

const spinner = ref();

const props = defineProps({
  message: {
    type: String,
    default: ''
  }
});

onMounted(async () => {

  Lottie.loadAnimation({
    container: spinner.value,
    loop: true,
    autoplay: true,
    animationData
  });

});
</script>

<template>
  <div class="spinner-container">
    <div class="contents">
      <div ref="spinner" class="spinner"></div>
      <div class="message">{{props.message}}</div>
    </div>
  </div>
</template>