<style scoped>
.zoom-container {
  position: absolute;
  left: 20px;
  top: 20px;
  border-radius: 4px;
  background-color: red;
  width: 64px;
  transition: all 500ms;
  text-align: center;
  z-index: 100;
}

.zoom-container.disabled {
  opacity: 0.3;
  pointer-events: none;
}

.level-container {
  display: inline-block;
  text-align: center;
  width: 64px;
  padding: 10px 0px;
  vertical-align: middle;
}

.button {
  position: relative;
  display: inline-block;
  width: 36px;
  height: 36px;
  padding: 4px 0px;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
}

.zoom-fit {
  padding: 6px;
  width: 24px;
  height: 24px;
  margin: 10px;
  cursor: pointer;
}

@media screen and (max-width: 600px) {

  .zoom-container {
    top: calc( 100% - 20px );
    transform: translate( -50%, -100% );
    left: 50%;
    width: 192px;
  }

  .button {
    padding: 0px;
  }

  .zoom-fit {
    margin: 0px 10px;
  }

}

@media screen and (max-height: 300px) {

  .zoom-container {
    width: 192px;
  }

  .button {
    padding: 0px;
  }

  .zoom-fit {
    margin: 0px 10px;
  }

}
</style>
<script setup>
const emits = defineEmits(['clickZoomFitButton', 'clickZoomInButton', 'clickZoomOutButton']);

const props = defineProps({
  zoom: {
    type: Number,
    default: 1
  },
  backgroundColor: {
    type: Object,
    default() {
      return { r: 255, g: 255, b: 255 };
    }
  },
  fontColor: {
    type: Object,
    default() {
      return { r: 0, g: 0, b: 0 };
    }
  },
  disabled: {
    type: Boolean,
    default: true
  }
});

const methods = {

  getZoomText: ( zoom ) => {

    if( 1000 * 1000 * 1000 < zoom ){
      return `1000M<`;
    }

    if( 1000 * 1000 < zoom ){
      return `${Math.round(zoom / 1000 / 1000)}M`;
    }

    if( 1000 < zoom ){
      return `${Math.round(zoom / 1000)}K`;
    }

    if( zoom < 0.001 * 0.001 * 0.001 ){
      return `<0.001μ`;
    }

    if( zoom < 0.001 * 0.001 ){
      return `${(zoom * 1000 * 1000).toFixed(3)}μ`;
    }

    if( zoom < 0.001 ){
      return `${(zoom * 1000).toFixed(3)}m`;
    }

    if( zoom < 1 ){
      return zoom.toFixed(3);
    }

    return Math.round(zoom);
  },

  onClickZoomFitButton: () => {
    emits('clickZoomFitButton');
  },

  onClickZoomInButton: () => {
    emits('clickZoomInButton');
  },

  onClickZoomOutButton: () => {
    emits('clickZoomOutButton');
  },

};

</script>
<template>
  <div class="zoom-container" :class="{disabled: props.disabled}" :style="{'background-color': `rgb(${props.backgroundColor.r},${props.backgroundColor.g},${props.backgroundColor.b})`}">
    <svg class="button zoom-fit heartbeat-hover" version="1.0" xmlns="http://www.w3.org/2000/svg"
         width="512.000000pt" height="512.000000pt" viewBox="0 0 512.000000 512.000000"
         preserveAspectRatio="xMidYMid meet"
         @click="methods.onClickZoomFitButton"
    >

      <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
         :fill="`rgb(${props.fontColor.r},${props.fontColor.g},${props.fontColor.b})`" stroke="none">
        <path d="M0 4350 l0 -770 255 0 255 0 0 515 0 515 515 0 515 0 0 255 0 255
-770 0 -770 0 0 -770z"/>
        <path d="M3580 4865 l0 -255 515 0 515 0 0 -515 0 -515 255 0 255 0 0 770 0
770 -770 0 -770 0 0 -255z"/>
        <path d="M0 770 l0 -770 770 0 770 0 0 255 0 255 -515 0 -515 0 0 515 0 515
-255 0 -255 0 0 -770z"/>
        <path d="M4610 1025 l0 -515 -515 0 -515 0 0 -255 0 -255 770 0 770 0 0 770 0
770 -255 0 -255 0 0 -515z"/>
      </g>
    </svg>
    <svg class="button heartbeat-hover" version="1.0" xmlns="http://www.w3.org/2000/svg"
         width="512.000000pt" height="512.000000pt" viewBox="0 0 512.000000 512.000000"
         preserveAspectRatio="xMidYMid meet"
         @click="methods.onClickZoomInButton"
    >

      <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
         :fill="`rgb(${props.fontColor.r},${props.fontColor.g},${props.fontColor.b})`" stroke="none">
        <path d="M2465 4033 c-41 -21 -74 -52 -93 -88 -15 -27 -18 -94 -22 -600 l-5
-570 -570 -5 c-506 -4 -573 -7 -600 -22 -69 -38 -105 -102 -105 -188 0 -86 36
-150 105 -188 27 -15 94 -18 600 -22 l570 -5 5 -570 c4 -506 7 -573 22 -600
38 -69 102 -105 188 -105 86 0 150 36 188 105 15 27 18 94 22 600 l5 570 570
5 c506 4 573 7 600 22 69 38 105 102 105 188 0 85 -44 163 -110 194 -17 8
-197 13 -595 16 l-570 5 -5 570 c-4 457 -8 575 -19 595 -22 41 -64 81 -100 96
-45 19 -148 17 -186 -3z"/>
      </g>
    </svg>
    <div class="level-container" :style="{color: `rgb(${props.fontColor.r},${props.fontColor.g},${props.fontColor.b})`}" v-tooltip.right="{theme: 'white', content: props.zoom}">
      {{ methods.getZoomText( props.zoom ) }}
    </div>
    <svg class="button heartbeat-hover" version="1.0" xmlns="http://www.w3.org/2000/svg"
         width="512.000000pt" height="512.000000pt" viewBox="0 0 512.000000 512.000000"
         preserveAspectRatio="xMidYMid meet"
         @click="methods.onClickZoomOutButton"
    >

      <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
         :fill="`rgb(${props.fontColor.r},${props.fontColor.g},${props.fontColor.b})`" stroke="none">
        <path d="M1185 2753 c-78 -41 -115 -103 -115 -193 0 -89 37 -152 112 -191 36
-18 80 -19 1378 -19 1298 0 1342 1 1378 19 75 39 112 101 112 191 0 66 -16
107 -59 153 -55 60 27 57 -1435 57 -1203 -1 -1344 -2 -1371 -17z"/>
      </g>
    </svg>
  </div>
</template>