<style scoped>
.search-container {
  position: relative;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .search-input {
    position: relative;
    width: calc( 100% - 20px );
    padding: 0px 10px;
    height: 30px;
    border: none;
    border-radius: 4px;
    outline: none;

    &.has-content {
      border-radius: 4px 0px 0px 4px;
    }
  }

  .search-clear {
    height: 30px;
    width: 40px;
    background-color: transparent;
    border: none;
    cursor: pointer;
    text-align: center;
    border-radius: 0px 4px 4px 0px;

    > svg {
      width: 12px;
      height: 12px;
    }
  }

}

input::placeholder {
  color: rgba(0, 0, 0, 0.5);
}
</style>
<script setup>
import {ref, watch} from "vue";

const props = defineProps({

  modelValue: {
    type: String
  },

  backgroundColor: {
    type: Object,
    default(){
      return {
        r: 255,
        g: 255,
        b: 255,
        a: 1
      }
    }
  },

  fontColor: {
    type: Object,
    default(){
      return {
        r: 0,
        g: 0,
        b: 0,
        a: 1
      }
    }
  },

});
const searchInput = ref();
const value = ref(props.modelValue);
const emits = defineEmits(['update:modelValue']);

watch( () => value.value, () => {

  emits('update:modelValue', value.value);

} );

watch( () => props.modelValue, () => {

  value.value = props.modelValue;

} );

const methods = {
  clear: () => {

    value.value = '';

  },

  onClickClearButton: () => {

    searchInput.value.focus();
    methods.clear();

  },

}

defineExpose({

  clear: () => {

    methods.clear();

  }

});

</script>

<template>
  <div class="search-container">
    <input ref="searchInput" class="search-input" :class="{'has-content': 0 < value.length}" type="text" placeholder="검색" v-model="value"
           :style="{'background-color': `rgba(${props.backgroundColor.r},${props.backgroundColor.g},${props.backgroundColor.b}, ${props.backgroundColor.a})`, 'color': `rgba(${props.fontColor.r},${props.fontColor.g},${props.fontColor.b},${props.fontColor.a})`}">
    <button v-show="0 < value.length" class="search-clear" :style="{'background-color': `rgba(${props.backgroundColor.r},${props.backgroundColor.g},${props.backgroundColor.b}, ${props.backgroundColor.a})`, 'color': `rgba(${props.fontColor.r},${props.fontColor.g},${props.fontColor.b},${props.fontColor.a})`}" @click="methods.onClickClearButton">
      <svg xmlns="http://www.w3.org/2000/svg"
           width="512.000000pt" height="512.000000pt" viewBox="0 0 512.000000 512.000000"
           preserveAspectRatio="xMidYMid meet">

        <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
           :fill="`rgb(${props.fontColor.r},${props.fontColor.g},${props.fontColor.b})`" stroke="none">
          <path d="M271 5109 c-104 -20 -194 -91 -239 -187 -22 -47 -27 -71 -27 -137 0
-155 -68 -78 1075 -1220 l1010 -1010 -1019 -1020 c-827 -829 -1022 -1029
-1041 -1070 -34 -71 -35 -199 -2 -270 29 -63 93 -129 157 -163 72 -37 187 -42
270 -12 58 22 94 56 1083 1044 l1022 1021 1018 -1016 c817 -816 1027 -1021
1067 -1040 42 -20 65 -24 145 -24 83 0 101 4 145 27 62 32 129 103 158 166 32
68 30 197 -3 267 -19 41 -214 241 -1041 1070 l-1019 1020 1010 1010 c1143
1142 1075 1065 1075 1220 0 65 -5 90 -26 135 -81 172 -284 242 -454 158 -26
-13 -391 -370 -1057 -1036 l-1018 -1017 -1017 1017 c-667 666 -1032 1023
-1058 1036 -34 17 -145 45 -164 41 -3 -1 -26 -5 -50 -10z"/>
        </g>
      </svg>
    </button>
  </div>
</template>

