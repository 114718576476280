<style scoped>
.error-container {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  z-index: 40;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.image {
  position: relative;
  width: 50%;
  height: 50%;
  max-width: 128px;
  max-height: 128px;
  background-image: url("@/assets/images/error.png");
  background-size: 100%;
}

.message {
  font-family: sans-serif;
  font-size: 18px;
  text-align: center;
  color: #fff;
  height: 18px;
  text-shadow: -1px 0 #000, 0 1px #000, 1px 0 #000, 0 -1px #000;
}
</style>

<script setup type="module">
const props = defineProps({
  message: {
    type: String,
    default: `관리자에게 문의하세요.`
  }
});
</script>

<template>
  <div class="error-container">
    <div class="image"></div>
    <pre class="message">{{props.message}}</pre>
  </div>
</template>