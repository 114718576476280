<style scoped>
.no-items {
  position: relative;
  height: 100%;
  top: 50%;
  text-align: center;
}

.search-filter {
  padding: 0px 10px;
}
.dxfs {
  position: relative;
  padding: 10px 10px;
  width: calc( 100% - 20px );
  font-size: 18px;
  height: calc( 100% - 60px );
  overflow: auto;
}

.dxf {
  background-color: white;
  padding: 10px;
  border-radius: 4px;
  margin-top: 10px;
  border-radius: 4px;
  cursor: pointer;
  transition: all 250ms;
  opacity: 0.7;
}

.dxf:first-child {
  margin-top: 0px;
}

.dxf:hover {
  opacity: 1;
}

.dxf > * {
  display: inline-block;
  font-size: 0px;
  vertical-align: middle;
}

.dxf .name {
  font-size: 18px;
}
.dxf .size {
  font-size: 14px;
  opacity: 0.5;
}

.check {
  position: absolute;
  width: 16px;
  height: 16px;
  z-index: 20;
  transform: translate(-50%, -50%);
  border-radius: 50px;
}

.tooltip-arrow {
  z-index: 9999;
}
</style>
<script setup>
import Lottie from "lottie-web";
import {onMounted, watch, ref, computed} from "vue";
import TheSide from "@/components/side/TheSide.vue";
import Util from "@/modules/Util.js";
import animationData from "@/assets/images/lotties/check.json";
import titleImage from "@/assets/images/dxf.png";
import SearchFilter from "@/components/SearchFilter.vue";

const check = ref();
const visibleCheck = ref(false);
const dxf = ref();
const dxfContainer = ref();
const searchKeyword = ref('');

const props = defineProps({
  selectDxfId: {
    type: String,
    required: false,
  },
  dxfs: {
    type: Array,
    default() {
      return [];
    }
  },
  backgroundColor: {
    type: Object,
    default() {
      return { r: 255, g: 255, b: 255 };
    }
  },
  fontColor: {
    type: Object,
    default() {
      return { r: 0, g: 0, b: 0 };
    }
  }
});

const visibleDxfs = computed(() => {

  const keyword = searchKeyword.value.toLowerCase();
  return props.dxfs.some((dxf) => {

    return -1 < dxf.name.toLowerCase().indexOf( keyword );

  });

});

const data = {
  lottie: null
};

const emits = defineEmits(['clickBackButton', 'clickItem']);

const methods = {
  onClickBackButton: () => {
    emits('clickBackButton');
  },

  onClickDxf: (item) => {
    emits('clickItem', item);
  },

  updateCheckColor: () => {
    Util.traverseElement.call( check.value, ( element ) => {

      if( element.getAttribute( 'stroke' ) ) {
        element.setAttribute('stroke', `rgb(${props.fontColor.r}, ${props.fontColor.g}, ${props.fontColor.b})`)
      }

    } );
  },

  getBoxShadow: (item) => {

    if( props.selectDxfId === item.id ){
      return `0px 0px 1px 1px rgb(${props.fontColor.r},${props.fontColor.g},${props.fontColor.b})`;
    }

    return 'none';

  },

  hideCheck: () => {
    data.lottie.setDirection( -1 );
    data.lottie.play();
  },

  updateVisibleCheckBySelectedDxfId: async () => {

    await new Promise((resolve) => {
      setTimeout(() => {
        resolve();
      });
    });

    if(!dxf.value){
      return;
    }

    const itemIndex = props.dxfs.findIndex(( dxf ) => {
      return dxf.id === props.selectDxfId;
    });

    if( itemIndex < 0 ){
      visibleCheck.value = false;
      return;
    }


    const item = dxf.value[itemIndex];
    if( item === undefined ){
      visibleCheck.value = false;
      return;
    }

    if( item.style.display === 'none' ){
      visibleCheck.value = false;
      return;
    }

    const dxfsRect = dxfContainer.value.getBoundingClientRect();
    const itemRect = item.getBoundingClientRect();
    check.value.style.left = `${itemRect.left - dxfsRect.left}px`;
    check.value.style.top = `${itemRect.top - dxfsRect.top + dxfContainer.value.scrollTop}px`;
    visibleCheck.value = true;
    data.lottie.goToAndStop(0, true);
    data.lottie.setDirection( 1 );
    data.lottie.play();
  },

  getVisibleDxf: (dxf) => {
    const _searchKeyword = searchKeyword.value?.toLowerCase();
    return -1 < dxf.name.toLowerCase().indexOf( _searchKeyword );
  }

}

watch(() => searchKeyword.value, () => {
  methods.updateVisibleCheckBySelectedDxfId();
});

watch(() => props.fontColor, () => {
  methods.updateCheckColor();
});

onMounted(() => {
  data.lottie = Lottie.loadAnimation({
    name: 'check',
    container: check.value,
    loop: false,
    autoplay: true,
    animationData
  });

  data.lottie.addEventListener('DOMLoaded', () => {
    methods.updateCheckColor();
  });

  data.lottie.setSpeed( 2 );

});

defineExpose({
  hideCheck: () => {
    methods.hideCheck();
  },
  updateVisibleCheckBySelectedDxfId: () => {
    methods.updateVisibleCheckBySelectedDxfId();
  }
});
</script>
<template>
  <the-side
      :title-image="titleImage"
      :title-text="'DXF 목록'"
      :background-color="props.backgroundColor"
      :font-color="props.fontColor"
      @clickBackButton="methods.onClickBackButton"
  >
    <search-filter ref="searchFilter" v-model="searchKeyword"
                   class="search-filter"
                   :backgroundColor="{ r: props.fontColor.r, g: props.fontColor.g, b: props.fontColor.b, a: 0.2 }"
                   :fontColor="{ r: props.fontColor.r, g: props.fontColor.g, b: props.fontColor.b, a: 1 }"
    ></search-filter>
    <div ref="dxfContainer" class="dxfs">
      <div v-show="visibleDxfs">
        <div v-show="visibleCheck" ref="check" class="check" :style="{'background-color': `rgb(${props.backgroundColor.r},${props.backgroundColor.g},${props.backgroundColor.b})`}"></div>
        <div v-show="methods.getVisibleDxf(item)" ref="dxf" :key="item.id" v-for="item in props.dxfs" class="dxf"
             :style="{
          'background-color': `rgba(${255 - props.fontColor.r},${255 - props.fontColor.g},${255 - props.fontColor.b}, 0.5)`,
          'box-shadow': methods.getBoxShadow(item)
        }"
             @click="methods.onClickDxf(item)"
        >
          <div :style="{'color': `rgb(${props.fontColor.r},${props.fontColor.g},${props.fontColor.b})`}">
            <span class="name">{{item.name}}</span><br>
            <span class="size">{{Util.toMb(item.size)}}Mb</span>
          </div>
        </div>
      </div>
      <div v-show="!visibleDxfs" class="no-items">도면이 없습니다.</div>
    </div>
  </the-side>
</template>