<style scoped>
@media screen and (max-width: 600px) {
  .user-text {
    display: none !important;
  }
}

header {
  position: relative;
  height: 50px;
  transition: all 500ms;
}

.product-name {
  font-weight: bold;
  font-family: sans-serif;
  font-size: 24px;
  padding: 11px;
  width: calc( 100% - 50px );
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.version {
  font-weight: normal;
  font-size: 14px;
}

.user-text {
  position: absolute;
  display: flex;
  align-items: center;
  font-size: 14px;
  top: 0px;
  right: 60px;
  height: 50px;
}

.hamburger-button {
  position: absolute;
  right: 0px;
  top: 0px;
  width: 40px;
  height: 40px;
  padding: 5px;
  cursor: pointer;
  border-radius: 5px;
  transition: all 500ms;
}

.sub-buttons {
  position: fixed;
  display: flex;
  right: 0px;
  transition: all 500ms;
  z-index: 50;
  top: 50px;
  opacity: 1;
  height: calc( 100% - 50px );
  flex-direction: column;
  flex-wrap: wrap-reverse;
  pointer-events: none;
  transform: translateY(0px) scaleY(1);
}

.slide-fade-enter-active {
  transition: all 500ms;
}

.slide-fade-leave-active {
  transition: all 500ms;
}
.slide-fade-enter-from,
.slide-fade-leave-to {
  top: 0px;
  transform: translateY(-50%) scaleY(0);
  opacity: 0;
}

.sub-button {
  margin-top: 10px;
  margin-right: 10px;
  width: 50px;
  height: 50px;
  border: none;
  border-radius: 50px;
  background-size: 30px;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
  pointer-events: auto;
  transition: all 500ms;
}

.sub-buttons.disabled .disable-target {
  pointer-events: none;
  opacity: 0.1 !important;
}

.sub-button.color-rgb {
  background-image: url("@/assets/images/color-rgb.png");
}

.sub-button.color-black {
  background-image: url("@/assets/images/color-black.png");
}

.sub-button.color-white {
  background-image: url("@/assets/images/color-white.png");
}

.sub-button.layers {
  background-image: url("@/assets/images/layers.png");
}

.sub-button.information {
  background-image: url("@/assets/images/information.png");
}

.sub-button.download {
  background-image: url("@/assets/images/download.png");
}

.sub-button.sample {
  background-image: url("@/assets/images/dxf.png");
}

.sub-button.upload {
  background-image: url("@/assets/images/upload.png");
}

.sub-button.api {
  background-image: url("@/assets/images/api.png");
}

.sub-button.logout {
  background-image: url("@/assets/images/logout.png");
}

.display-types {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 50px;
  margin-right: 10px;
  margin-bottom: 10px;
  pointer-events: none;
}

.display-type {
  position: absolute;
  left: 0px;
  top: 0px;
  opacity: 0;
  pointer-events: none;
  transition: all 500ms;
}

.display-type.show {
  opacity: 1;
  pointer-events: auto;
}

</style>
<script setup>
import {ref, onMounted ,watch} from 'vue';
import Lottie from 'lottie-web';
import Util from '@/modules/Util.js';
import animationData from "@/assets/images/lotties/hamburger.json";
import packageJson from "@/../package.json";

const hamburgerButton = ref();

const data = {
  lottie: null,
};

const props = defineProps({
  visibleMenu: {
    type: Boolean,
    default: false
  },
  backgroundColor: {
    type: Object,
    default() {
      return { r: 255, g: 255, b: 255 };
    }
  },
  fontColor: {
    type: Object,
    default() {
      return { r: 0, g: 0, b: 0 };
    }
  },
  displayColorType: {
    type: String,
    default: 'rgb'
  },
  disabled: {
    type: Boolean,
    default: true
  },
  tooltipTheme: {
    type: String,
    default: 'white'
  },
  userText: {
    type: String,
    default: ''
  }
});
const emits = defineEmits([
  'clickHamburgerButton',
  'clickDxfInformationButton',
  'clickLayerListButton',
  'clickDownloadButton',
  'clickSampleDxfButton',
  'clickRgbButton',
  'clickApiButton',
  'clickUploadButton'
]);

const methods = {
  onClickHamburgerButton: () => {
    emits('clickHamburgerButton');
  },

  onClickDxfInformationButton: () => {
    emits('clickDxfInformationButton');
  },

  onClickLayerListButton: () => {
    emits('clickLayerListButton');
  },

  onClickDownloadButton: () => {
    emits('clickDownloadButton');
  },

  onClickSampleDxfButton: () => {
    emits('clickSampleDxfButton');
  },

  onClickUploadButton: () => {
    emits('clickUploadButton');
  },

  onClickRgbButton: () => {
    emits('clickRgbButton');
  },

  onClickApiButton: () => {
    emits('clickApiButton');
  },

  onClickLogoutButton: () => {
    emits('clickLogoutButton');
  },

  updateHamburgerIconColor: () => {
    Util.traverseElement.call( hamburgerButton.value, ( element ) => {

      if( element.getAttribute( 'stroke' ) ) {
        element.setAttribute('stroke', `rgb(${props.fontColor.r}, ${props.fontColor.g}, ${props.fontColor.b})`)
      }

    } );
  }
};

watch(() => props.visibleMenu, ( visibleMenu ) => {
  const direction = visibleMenu ? 1 : -1;
  data.lottie.setDirection( direction );
  data.lottie.play();
});


watch(() => props.fontColor, () => {

  methods.updateHamburgerIconColor();

});

onMounted(() => {
  data.lottie = Lottie.loadAnimation({
    name: 'hamburger-button',
    container: hamburgerButton.value,
    loop: false,
    autoplay: false,
    animationData
  });

  data.lottie.addEventListener('DOMLoaded', () => {
    methods.updateHamburgerIconColor();
  });

  data.lottie.setSpeed( 2 );
});

</script>
<template>
  <header :style="{'background-color': `rgb(${props.backgroundColor.r},${props.backgroundColor.g},${props.backgroundColor.b})`}">
    <div>
      <div class="product-name" :style="{'color': `rgb(${props.fontColor.r},${props.fontColor.g},${props.fontColor.b})`}">
          <span>
            SGK CAD View
          </span>
          <span class="version">
            v{{packageJson.version}}
          </span>
      </div>
    </div>
    <div class="user-text" :style="{'color': `rgb(${props.fontColor.r},${props.fontColor.g},${props.fontColor.b})`}" v-html="userText"></div>
    <div ref="hamburgerButton" class="hamburger-button heartbeat-hover" @click="methods.onClickHamburgerButton"></div>
    <transition name="slide-fade">
      <div v-show="props.visibleMenu" class="sub-buttons" :class="{disabled: props.disabled}">
        <button class="sub-button heartbeat-hover information disable-target"
                :style="{'background-color': `rgb(${props.backgroundColor.r},${props.backgroundColor.g},${props.backgroundColor.b})`}"
                @click="methods.onClickDxfInformationButton" v-tooltip.left="{ content: '도면 정보', theme: props.tooltipTheme }"></button>
        <button class="sub-button heartbeat-hover layers disable-target"
                :style="{'background-color': `rgb(${props.backgroundColor.r},${props.backgroundColor.g},${props.backgroundColor.b})`}"
                @click="methods.onClickLayerListButton" v-tooltip.left="{ content: '레이어 목록', theme: props.tooltipTheme }"></button>
        <div class="display-types">
          <button class="sub-button heartbeat-hover display-type disable-target" :class="{'color-rgb': true, show: props.displayColorType === 'rgb'}"
                  :style="{'background-color': `rgb(${props.backgroundColor.r},${props.backgroundColor.g},${props.backgroundColor.b})`}"
                  @click="methods.onClickRgbButton" v-tooltip.left="{ content: '다채색으로 보는 중', theme: props.tooltipTheme }"
          ></button>
          <button class="sub-button heartbeat-hover display-type disable-target" :class="{'color-white': true, show: props.displayColorType === 'white'}"
                  :style="{'background-color': `rgb(${props.backgroundColor.r},${props.backgroundColor.g},${props.backgroundColor.b})`}"
                  @click="methods.onClickRgbButton" v-tooltip.left="{ content: '검은선으로 보는 중', theme: props.tooltipTheme }"
          ></button>
          <button class="sub-button heartbeat-hover display-type disable-target" :class="{'color-black': true, show: props.displayColorType === 'black'}"
                  :style="{'background-color': `rgb(${props.backgroundColor.r},${props.backgroundColor.g},${props.backgroundColor.b})`}"
                  @click="methods.onClickRgbButton" v-tooltip.left="{ content: '하얀선으로 보는 중', theme: props.tooltipTheme }"
          ></button>
        </div>
        <button class="sub-button heartbeat-hover download disable-target"
                :style="{'background-color': `rgb(${props.backgroundColor.r},${props.backgroundColor.g},${props.backgroundColor.b})`}"
                @click="methods.onClickDownloadButton" v-tooltip.left="{ content: '현재 영역 이미지 다운로드', theme: props.tooltipTheme }"></button>
        <button class="sub-button heartbeat-hover sample"
                :style="{'background-color': `rgb(${props.backgroundColor.r},${props.backgroundColor.g},${props.backgroundColor.b})`}"
                @click="methods.onClickSampleDxfButton" v-tooltip.left="{ content: '샘플 DXF 목록', theme: props.tooltipTheme }"></button>
        <button class="sub-button heartbeat-hover upload"
                :style="{'background-color': `rgb(${props.backgroundColor.r},${props.backgroundColor.g},${props.backgroundColor.b})`}"
                @click="methods.onClickUploadButton" v-tooltip.left="{ content: 'DXF 업로드', theme: props.tooltipTheme }"></button>
        <button class="sub-button heartbeat-hover api"
                :style="{'background-color': `rgb(${props.backgroundColor.r},${props.backgroundColor.g},${props.backgroundColor.b})`}"
                @click="methods.onClickApiButton" v-tooltip.left="{ content: 'API 문서', theme: props.tooltipTheme }"></button>
        <button class="sub-button heartbeat-hover logout"
                :style="{'background-color': `rgb(${props.backgroundColor.r},${props.backgroundColor.g},${props.backgroundColor.b})`}"
                @click="methods.onClickLogoutButton" v-tooltip.left="{ content: '로그아웃', theme: props.tooltipTheme }"></button>
      </div>
    </transition>
  </header>
</template>