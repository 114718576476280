<style scoped>
.container {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  /* @todo 없어져야할 요소 */
  overflow: hidden;
}

.page {
  position: relative;
  height: calc( 100% - 50px );
}

aside {
  position: fixed;
  right: 0px;
  top: 50px;
  width: 400px;
  height: calc( 100% - 50px );
  z-index: 100;
  transform: translateX(0px);
  opacity: 1;
}

.slide-fade-enter-active {
  transition: all 500ms;
}

.slide-fade-leave-active {
  transition: all 500ms;
}
.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(100%);
  opacity: 0;
}


.fade-enter-active {
  transition: all 500ms;
}

.fade-leave-active {
  transition: all 500ms;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

@media screen and (max-width: 600px) {
  aside {
    width: 100%;
    transform: translateY(0px);
  }

  .slide-fade-enter-from,
  .slide-fade-leave-to {
    transform: translateY(100%);
    opacity: 0;
  }
}
</style>
<script setup>
import {ref, watch} from 'vue';
import TheHeader from "@/components/TheHeader.vue";
import LayerList from "@/components/side/LayerList.vue";
import DxfList from "@/components/side/DxfList.vue";
import DxfInformation from "@/components/side/DxfInformation.vue";
import DxfViewer from "@/components/DxfViewer.vue";
import ZoomController from "@/components/ZoomController.vue";
import Util from "@/modules/Util.js";
import TheSpinner from "@/components/TheSpinner.vue";
import progressMessage from "@/assets/jsons/progressMessage.json";
import errorMessage from "@/assets/jsons/errorMessage.json";
import TheError from "@/components/TheError.vue";
import packageJson from "@/../package.json";
import Jwt from "@/modules/Jwt.js";
import cadApi from "@/api/cad.js";
import employeeApi from "@/api/employee.js";
import fileApi from "@/api/file.js";

const jwt = ref( Jwt.get() );
const visibleHeaderMenu = ref( false );
const visibleDxfInformation = ref( false );
const visibleLayerList = ref( false );
const visibleDxfList = ref( false );
const dxfInformation = ref(null);
const layers = ref([]);
const zoom = ref(1);
const headerBackgroundColor = ref({ r: 59, g: 68, b: 83 });
const headerFontColor = ref({ r: 255, g: 255, b: 255 });
const displayColorType = ref('rgb');
const selectDxfId = ref(null);
const uploadedDxfUrl = ref(null);
const visibleSpinner = ref(true);
const spinnerMessage = ref('');
const headerTooltipTheme = ref('white');
const layerTooltipTheme = ref('white');
const error = ref(null);
const dxfs = ref([]);
const userText = ref('');
const dxfViewer = ref();
const layerList = ref();
const dxfList = ref();

employeeApi.get().then((result) => {

  const employee = result.data;
  userText.value =  `${employee.empName} (${employee.empOfficeName || '프로'}/${employee.deptName || '-'})`;

});

cadApi.dxfs().then(async ( dxfsResult ) => {
  dxfs.value = dxfsResult.data;
  if(dxfs.value.length <= 0){
    visibleSpinner.value = false;
    error.value = "NotExistSampleDxfError";
    return;
  }

  selectDxfId.value = dxfs.value[0].id;
});

const fileInput = (() => {

  const fileInput = document.createElement('input');
  fileInput.type = 'file';
  fileInput.accept = '.dxf'
  fileInput.addEventListener('change', () => {
    if(!fileInput.files[0]){
      return;
    }

    spinnerMessage.value = progressMessage.fetch;
    visibleSpinner.value = true;
    uploadedDxfUrl.value = URL.createObjectURL(fileInput.files[0]);
    fileInput.value = null;
  });

  return fileInput;

})();

watch(() => selectDxfId.value, async () => {
  error.value = null;
  spinnerMessage.value = progressMessage.fetch;
  visibleSpinner.value = true;
  const blob = await fileApi.download(selectDxfId.value);
  uploadedDxfUrl.value = URL.createObjectURL( blob );

});

watch(() => visibleLayerList.value, () => {

  if( visibleLayerList.value ){
    return;
  }

  layerList.value.hideTooltipAll();

});

const methods = {
  hideAsideAll: () => {
    visibleDxfInformation.value = false;
    visibleLayerList.value = false;
    visibleDxfList.value = false;
  },

  onClickHeaderHamburgerButton: () => {
    visibleHeaderMenu.value = !visibleHeaderMenu.value;
    methods.hideAsideAll();
    layerList.value.hideTooltipAll();
  },

  onClickDxfInformationButton: () => {
    visibleHeaderMenu.value = false;
    visibleDxfInformation.value = true;
  },

  onClickDxfInformationBackButton: () => {
    visibleDxfInformation.value = false;
  },

  onClickLayerListButton: () => {
    visibleHeaderMenu.value = false;
    visibleLayerList.value = true;
  },

  onClickLayerListBackButton: () => {
    visibleLayerList.value = false;
  },

  onClickDxfListBackButton: () => {
    visibleDxfList.value = false;
  },

  onClickDownloadButton: () => {
    if( !dxfViewer.value.download ) {
      return;
    }

    dxfViewer.value.download();
  },

  onClickSampleDxfButton: () => {
    visibleHeaderMenu.value = false;
    visibleDxfList.value = true;
  },

  onLoadDxf: (_dxfInformation) => {
    dxfInformation.value = _dxfInformation;
    layers.value = dxfViewer.value.getLayers();
    if( Util.isMobile() ){
      methods.hideAsideAll();
    }
    methods.changeColorIterator.next(true);
    error.value = null;
    visibleSpinner.value = false;
  },

  onLoadDxfError: (_error) => {
    if( Util.isMobile() ){
      methods.hideAsideAll();
    }
    error.value = _error;
    visibleSpinner.value = false;
  },

  onProgressDxf: (stepName) => {
    spinnerMessage.value = progressMessage[stepName];
  },

  onDxfViewerViewChanged: () => {
    zoom.value = dxfViewer.value.getZoom();
  },

  updateVisibleLayerInDxf: (layer) => {
    const visibleLayerInDxf = layer.visible === true && layer.frozen === false;
    dxfViewer.value.setVisibleLayer( layer.name, visibleLayerInDxf );
  },

  onClickLayerBulbIcon: (layer) => {
    layer.visible = !layer.visible;
    methods.updateVisibleLayerInDxf(layer);
  },

  onClickLayerFreezeIcon: (layer) => {
    layer.frozen = !layer.frozen;
    methods.updateVisibleLayerInDxf(layer);
  },

  onClickLayerHeaderBulbIcon: () => {
    const everyVisible = layers.value.every(( layer ) => {
      return layer.visible;
    });

    layers.value.forEach(( layer ) => {
      layer.visible = !everyVisible;
      methods.updateVisibleLayerInDxf( layer );
    });
  },

  onClickLayerHeaderFreezeIcon: () => {
    const everyFrozen = layers.value.every(( layer ) => {
      return layer.frozen;
    });

    layers.value.forEach(( layer ) => {
      layer.frozen = !everyFrozen;
      methods.updateVisibleLayerInDxf( layer );
    });
  },

  onClickDxfItem: async ( item ) => {
    selectDxfId.value = item.id;
    dxfList.value.updateVisibleCheckBySelectedDxfId();
  },

  changeColorIterator: (function* () {

    const works = [

      function toColorLine() {
        dxfViewer.value.setLineColor();
        dxfViewer.value.setBackgroundColor(32, 40, 48);
        headerBackgroundColor.value = { r: 59, g: 68, b: 83 };
        headerFontColor.value = { r: 255, g: 255, b: 255 };
        displayColorType.value = 'rgb';
        headerTooltipTheme.value = 'white';
        layerTooltipTheme.value = 'white';
      },

      function toBlackLine() {
        dxfViewer.value.setLineColor( 0, 0, 0 );
        dxfViewer.value.setBackgroundColor( 255, 255, 255 );
        headerBackgroundColor.value = { r: 0, g: 0, b: 0 };
        headerFontColor.value = { r: 255, g: 255, b: 255 };
        displayColorType.value = 'white';
        headerTooltipTheme.value = 'black';
        layerTooltipTheme.value = 'white';
      },

      function toWhiteLine() {
        dxfViewer.value.setLineColor( 255, 255, 255 );
        dxfViewer.value.setBackgroundColor( 0, 0, 0 );
        headerBackgroundColor.value = { r: 255, g: 255, b: 255 };
        headerFontColor.value = { r: 0, g: 0, b: 0 };
        displayColorType.value = 'black';
        headerTooltipTheme.value = 'white';
        layerTooltipTheme.value = 'black';
      },

    ];

    let nowColorIndex = -1;
    while(true) {

      nowColorIndex = nowColorIndex + 1;
      if( nowColorIndex === works.length ){
        nowColorIndex = 0;
      }

      const work = works[ nowColorIndex ];
      work();
      const oneMoreStep = yield;
      if( oneMoreStep ){
        nowColorIndex = nowColorIndex -1;
      }
    }

  })(),

  onClickRgbButton: () => {
    methods.changeColorIterator.next();
  },

  onClickUploadButton: () => {
    fileInput.click();
  },

  onClickApiButton: () => {
    const sgkDxfViewerVersion = packageJson.dependencies["@sgk-npm/sgk-dxf-viewer"].replace("^", "");
    window.open(`https://product.smartgeokit.com/sgk-dxf-viewer/v${sgkDxfViewerVersion}/docs/index.html`);
  },

  onClickLogoutButton: () => {
    Jwt.delete();
  },

  onClickZoomControllerFitButton: () => {
    dxfViewer.value.zoomFit();
  },

  onClickZoomControllerInButton: () => {
    dxfViewer.value.zoomIn();
  },

  onClickZoomControllerOutButton: () => {
    dxfViewer.value.zoomOut();
  },

  onDxfListTransitionEnter: () => {
    setTimeout(() => {
      dxfList.value.updateVisibleCheckBySelectedDxfId();
    }, 500);
  },

  onDxfListTransitionLeave: () => {
    dxfList.value.hideCheck();
  }
};
</script>
<template>
  <div v-if="jwt" class="container">
    <the-header :visibleMenu="visibleHeaderMenu"
                :fontColor="headerFontColor"
                :backgroundColor="headerBackgroundColor"
                :displayColorType="displayColorType"
                :disabled="error"
                :tooltip-theme="headerTooltipTheme"
                :user-text="userText"
                @clickHamburgerButton="methods.onClickHeaderHamburgerButton"
                @clickDxfInformationButton="methods.onClickDxfInformationButton"
                @clickLayerListButton="methods.onClickLayerListButton"
                @clickDownloadButton="methods.onClickDownloadButton"
                @clickSampleDxfButton="methods.onClickSampleDxfButton"
                @clickRgbButton="methods.onClickRgbButton"
                @clickUploadButton="methods.onClickUploadButton"
                @clickApiButton="methods.onClickApiButton"
                @clickLogoutButton="methods.onClickLogoutButton"
    ></the-header>
    <main class="page">
      <DxfViewer
          ref="dxfViewer"
          :dxf-url="uploadedDxfUrl"
          :disabled="error"
          @createdViewer="methods.onCreatedViewer"
          @loadDxf="methods.onLoadDxf"
          @viewChanged="methods.onDxfViewerViewChanged"
          @progress="methods.onProgressDxf"
          @loadError="methods.onLoadDxfError"
      />
      <transition name="fade">
        <the-error v-show="error" :message="errorMessage[error]"></the-error>
      </transition>
      <zoom-controller
          :zoom="zoom"
          :fontColor="headerFontColor"
          :backgroundColor="headerBackgroundColor"
          :disabled="error"
          @clickZoomFitButton="methods.onClickZoomControllerFitButton"
          @clickZoomInButton="methods.onClickZoomControllerInButton"
          @clickZoomOutButton="methods.onClickZoomControllerOutButton"
      ></zoom-controller>
    </main>
    <transition name="slide-fade">
      <aside v-show="visibleDxfInformation">
        <DxfInformation :dxfInformation="dxfInformation"
                        :fontColor="headerFontColor"
                        :backgroundColor="headerBackgroundColor"
                        @clickBackButton="methods.onClickDxfInformationBackButton"
        ></DxfInformation>
      </aside>
    </transition>
    <transition name="slide-fade">
      <aside v-show="visibleLayerList">
        <layer-list ref="layerList"
                    :items="layers"
                    :fontColor="headerFontColor"
                    :backgroundColor="headerBackgroundColor"
                    :tooltip-theme="layerTooltipTheme"
                    @clickBackButton="methods.onClickLayerListBackButton"
                    @clickBulbIcon="methods.onClickLayerBulbIcon"
                    @clickFreezeIcon="methods.onClickLayerFreezeIcon"
                    @click-header-bulb-icon="methods.onClickLayerHeaderBulbIcon"
                    @click-header-freeze-icon="methods.onClickLayerHeaderFreezeIcon"
        ></layer-list>
      </aside>
    </transition>
    <transition name="slide-fade" @enter="methods.onDxfListTransitionEnter" @leave="methods.onDxfListTransitionLeave">
      <aside v-show="visibleDxfList">
        <dxf-list
            ref="dxfList"
            :selectDxfId="selectDxfId"
            :dxfs="dxfs"
            :fontColor="headerFontColor"
            :backgroundColor="headerBackgroundColor"
            @clickBackButton="methods.onClickDxfListBackButton"
            @clickItem="methods.onClickDxfItem"
        ></dxf-list>
      </aside>
    </transition>
  </div>
  <transition name="fade">
    <the-spinner v-show="visibleSpinner" :message="spinnerMessage"></the-spinner>
  </transition>
</template>