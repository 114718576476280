import commonApi from '@/api/common';
export default {
    dxfs: async () => {

        const method = 'GET';
        const url = `${process.env.VUE_APP_API_END_POINT}/cad/dxf`;

        const response = await commonApi.request(url, method);
        return response.json();

    }
}