<style scoped>
.container {
  position: relative;
  left: 0px;
  top: 0px;
}

.title {
  position: relative;
  text-align: center;
  margin: 20px 0px;
  padding: 0px 72px 0px 0px;
  font-size: 24px;
  font-weight: bold;
}

.title > * {
  vertical-align: middle;
}

.back-button {
  float: left;
  margin-top: 0px;
  margin-left: 20px;
  width: 24px;
  height: 24px;
  padding: 4px;
  border: none;
  cursor: pointer;
  transition: all 250ms;
}

.back-button:hover {
  width: 32px;
  height: 32px;
  padding: 0px;
}

.contents {
  position: relative;
  overflow-y: auto;
  height: calc( 100% - 69px );
}

.grid {
  display: grid;
  padding: 20px 40px;
  width: calc( 100% - 80px );
  grid-template-columns: 1fr 3fr;
  font-size: 18px;
  column-gap: 20px;
  grid-auto-rows: minmax(40px, auto);
  justify-items: left;
  align-items: center;
}

.col-1 {
  font-weight: bold;
}

.col-2 {
  text-align: left;
}

.image-container {
  margin-top: 20px;
  margin-left: 20px;
  width: calc( 100% - 40px );
  height: 200px;
  border-radius: 4px;
  background-color: black;
}

.image-container > img {
  object-fit: scale-down;
  width: 100%;
  height: 100%;
}
</style>
<script setup>
const emits = defineEmits(['clickBackButton']);
const props = defineProps({
  titleImage: {
    type: String,
    default: '',
  },
  titleText: {
    type: String,
    default: '',
  },
  backgroundColor: {
    type: Object,
    default() {
      return { r: 255, g: 255, b: 255 };
    }
  },
  fontColor: {
    type: Object,
    default() {
      return { r: 0, g: 0, b: 0 };
    }
  }
});

const methods = {
  onClickBackButton() {
    emits('clickBackButton');
  }
}

</script>
<template>
  <div class="container" :style="{
    'background-color': `rgba(${props.backgroundColor.r},${props.backgroundColor.g},${props.backgroundColor.b}, 0.9)`,
    'color': `rgb(${props.fontColor.r},${props.fontColor.g},${props.fontColor.b})`
  }">
    <div class="title">
      <svg class="back-button" @click="methods.onClickBackButton" xmlns="http://www.w3.org/2000/svg"
           width="64.000000pt" height="64.000000pt" viewBox="0 0 64.000000 64.000000"
           preserveAspectRatio="xMidYMid meet">

        <g transform="translate(0.000000,64.000000) scale(0.100000,-0.100000)"
           :fill="`rgb(${props.fontColor.r},${props.fontColor.g},${props.fontColor.b})`" stroke="none">
          <path d="M59 561 c-24 -19 -24 -20 -24 -241 0 -278 -12 -260 179 -260 126 0
    134 1 154 23 16 17 22 36 22 70 0 76 -35 69 -42 -8 l-3 -40 -124 -3 c-82 -2
    -128 1 -137 9 -20 17 -20 401 0 418 9 8 55 11 137 9 l124 -3 3 -40 c7 -78 42
    -84 42 -6 0 37 -5 52 -23 69 -21 20 -34 22 -154 22 -114 0 -134 -2 -154 -19z"/>
          <path d="M463 434 c-4 -10 8 -30 32 -54 l39 -40 -195 -2 c-165 -3 -194 -5
    -194 -18 0 -13 29 -15 194 -18 l195 -2 -39 -40 c-24 -24 -36 -44 -32 -54 4 -9
    10 -16 14 -16 5 0 38 29 73 65 l64 65 -64 65 c-35 36 -68 65 -73 65 -4 0 -10
    -7 -14 -16z"/>
        </g>
      </svg>

      <img :src="props.titleImage" width="24" height="24">
      <span>&nbsp;{{props.titleText}}</span>
    </div>
    <div class="contents">
      <slot></slot>
    </div>
  </div>
</template>